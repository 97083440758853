import { useSelector } from 'react-redux'
import classNames from 'classnames'
import QRCode from 'qrcode.react'

import MobileAppJourneyImage from '../../../../assets/images/mock/mobileAppJourneyMock.png'
import ButtonLink from '../../../common/buttons/ButtonLink'
import Calendar from './DashboardCalendar/Calendar'
import Schedule from './DashboardCalendar/Schedule'

import classes from './HomeTab.module.scss'

const HomeTab = () => {
  const user = useSelector(state => state.user.current_user)

  return (
    <div className={classNames(classes.homeTab)}>
      <main className={classNames(classes.homeWrapper, 'row')}>
        <article className='col-12 col-md-6 d-flex flex-column align-items-center align-items-md-end mb-4 mb-md-0'>
          <Calendar />
          {user.communities.length === 0 && (
            <div className='mt-auto'>
              <Schedule />
            </div>
          )}
        </article>

        {user.communities.length === 0 ? (
          <article className='col-12 col-md-6 d-flex flex-column align-items-center align-items-md-start'>
            <div className={classNames(classes.mobileAppDownload, 'd-flex flex-column flex-sm-row h-100')}>
              <img src={MobileAppJourneyImage} alt='Op36 App mock-up' className={classes.mobileAppJourneyImage}/>
              <section className={classNames(classes.mobileAppInstructions, 'd-flex flex-column')}>
                <h5 className={classNames(classes.qrCodeText, 'd-block d-md-none mb-3')}>
                  Final Step:<br /> <span className='font-weight-normal'>Download the mobile app</span>
                </h5>

                <h5 className={classNames(classes.qrCodeText, 'd-none d-md-block')}>
                  Final Step:<br /> <span className='font-weight-normal'>Scan the QR Code and download the mobile app:</span>
                </h5>
                <div className={classNames(classes.qrCodeWrapper, 'd-none d-md-flex justify-content-center align-items-center my-3')}>
                  <QRCode value='https://qrco.de/bbQ6EO' />
                </div>

                <section className={classes.appFacilitiesList}>
                  <div className='d-flex'>
                    <div className={classes.bulletPoint}></div>
                    <p className='ml-2'>Stay up to date with Schedules and Coach Announcements!</p>
                  </div>
                  <div className='d-flex'>
                    <div className={classes.bulletPoint}></div>
                    <p className='ml-2'>View Curriculum and Golf Tools to guide you through your journey!</p>
                  </div>
                  <div className='d-flex'>
                    <div className={classes.bulletPoint}></div>
                    <p className='ml-2'>Register easily for future programs!</p>
                  </div>
                </section>

                <ButtonLink navigateTo='programs' className='d-none d-md-block'>
                  Find a Program
                </ButtonLink>

                <div className='w-100 d-flex align-items-center d-md-none'>
                  <ButtonLink navigateTo={'https://qrco.de/bbQ6EO'} isExternal={true} target={'_blank'} className={classes.mobileAppButton}>
                    Get the mobile app
                  </ButtonLink>
                </div>
              </section>
            </div>
          </article>
        ) : (
          <div className='col-12 col-md-6 d-flex justify-content-center justify-content-md-start'>
            <Schedule className='mt-0'/>
          </div>
        )}
      </main>

      {user.communities.length > 0 && (
        <footer className={classNames(classes.mobileQRFooter, 'w-100 d-flex align-items-center')}>
          <h5 className={classNames(classes.qrCodeText, 'd-none d-md-block')}>Scan the QR Code and download the mobile app: </h5>
          <div className={classNames(classes.qrCodeWrapper, 'd-none d-md-flex justify-content-center align-items-center ml-auto')}>
            <QRCode size={52} value='https://qrco.de/bbQ6EO' />
          </div>

          <div className='w-100 d-flex align-items-center d-md-none'>
            <ButtonLink navigateTo={'https://qrco.de/bbQ6EO'} isExternal={true} target={'_blank'} className={classes.mobileAppButton}>
              Get the mobile app
            </ButtonLink>
          </div>
        </footer>
      )}
    </div>
  )
}

export default HomeTab
