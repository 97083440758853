import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import invitationMobileImage from '../../../assets/images/auth/invitation-mobile.webp'
import { getInvitation } from '../../../actions/invitationActions'

import classes from './InvitationPage.module.scss'
import Loader from '../../common/Op36Loader-web'
import ButtonLink from '../../common/buttons/ButtonLink'

const InvitationPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const invitation = useSelector(state => state.invitation)
  const community = invitation.community
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    setLoader(true)
    dispatch(getInvitation(params.id))
      .catch(_ => setError('Invitation does not exist or it has expired.'))
      .finally(_ => setLoader(false))
  }, [dispatch, params.id])

  const onInvitationAccept = () => {
    localStorage.setItem('invitationId', invitation.id)
    localStorage.setItem('communityId', community.id)

    navigate('/', { replace: true })
  }

  return (
    <div className={classNames(classes.invitationPage, 'd-flex')}>
      <div className={classNames(classes.mainCard, 'd-flex flex-column justify-content-center align-items-center m-auto')}>
        {loader ? (
          <Loader message='loading invitation' />
        ) : (
          <>
            {error ? (
              <div className='d-flex flex-column w-100 h-100'>
                <h2>This invitation is invalid!</h2>

                <h4>{error}</h4>

                <div className='w-100 d-flex justify-content-center align-items-center mt-auto'>
                  <ButtonLink navigateTo='/log-in' className={classes.actionLink}>
                    Log In
                  </ButtonLink>
                </div>
              </div>
            ) : (
              <div className='h-100 d-flex flex-column'>
                <div className='row'>
                  <main className='col-12 col-md-7 d-flex flex-column justify-content-center'>
                    <header className='mb-5'>
                      <div className='round-image-wrapper mb-3'>
                        <img className='round-image' src={community.photo.file_url} alt='community avatar' />
                      </div>

                      <h4>{community.name}</h4>
                    </header>

                    <h2 className='mb-2'>Hello {invitation.first_name}!</h2>
                    <h4 className='mb-4'>Accept Invite & Create Account</h4>

                    <p className={classes.inviteText}>
                      Accept the invite and create your account.
                      This will allow you to access the App.
                      If you already have an account, you will be accepted into this community.
                    </p>
                  </main>

                  <aside className='col-12 col-md-5 text-center mt-3 mt-md-0'>
                    <img src={invitationMobileImage} alt='Invitation mobile' className={classes.invitationMobileImage}></img>
                  </aside>
                </div>

                <div className='w-100 d-flex justify-content-center align-items-center mt-auto'>
                  <button type='button' onClick={onInvitationAccept} className={classNames(classes.acceptInviteButton, 'btn btn-primary')}>
                    Accept Invite
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default InvitationPage
