import * as constants from './actionTypes'
import * as common from './commonActions'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { serialize } from '../utility/serialization'
import _ from 'lodash'

import Axios from '../axios'

export function successRequestingUser(response) {
  if (response.status === 200) {
    // console.error("ADDING USER STORE: " + response.data.user)
    return { type: constants.ADD_USER, user: response.data.user }
  } else {
    console.error('ERROR ON GETTING USER ACTIVITIES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRefreshingCurrentUser(response) {
  if (response.status === 200) {
    // console.error("ADDING USER STORE: " + response.data.user)
    return { type: constants.REFRESH_USER, user: response.data.user }
  } else {
    console.error('ERROR ON GETTING USER ACTIVITIES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserActivities(response) {
  if (response.status === 200) {
    // console.error("ADDING USER ACTIVITIES TO STORE: " + response.data)
    return {
      type: constants.ADD_USER_ACTIVITIES,
      activities: response.data.activities,
    }
  } else {
    console.error('ERROR ON GETTING USER ACTIVITIES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserPlayActivities(response) {
  if (response.status === 200) {
    // console.error("ADDING USER PLAY ACTIVITIES TO STORE: " + response.data)
    return {
      type: constants.ADD_USER_PLAY_ACTIVITIES,
      play_activities: response.data.activities,
    }
  } else {
    console.error('ERROR ON GETTING USER PLAY ACTIVITIES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserGoalActivities(response) {
  if (response.status === 200) {
    // console.error("ADDING USER PLAY ACTIVITIES TO STORE: " + response.data)
    return {
      type: constants.ADD_USER_GOAL_ACTIVITIES,
      goal_activities: response.data.activities,
    }
  } else {
    console.error('ERROR ON GETTING USER GOAL ACTIVITIES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserTrainActivities(response) {
  if (response.status === 200) {
    // console.error("ADDING USER PLAY ACTIVITIES TO STORE: " + response.data)
    return {
      type: constants.ADD_USER_TRAIN_ACTIVITIES,
      train_activities: response.data.activities,
    }
  } else {
    console.error('ERROR ON GETTING USER TRAIN ACTIVITIES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserObjectiveActivities(response) {
  if (response.status === 200) {
    // console.error("ADDING USER PLAY ACTIVITIES TO STORE: " + response.data)
    return {
      type: constants.ADD_USER_OBJECTIVE_ACTIVITIES,
      objective_activities: response.data.activities,
    }
  } else {
    console.error('ERROR ON GETTING USER OBJECTIVE ACTIVITIES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserStats(response) {
  if (response.status === 200) {
    // console.error("ADDING USER STATS TO STORE: " + response.data.stats)
    return { type: constants.ADD_USER_STATS, stats: response.data.stats }
  } else {
    console.error('ERROR ON GETTING USER STATS')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserGoals(response) {
  if (response.status === 200) {
    return { type: constants.ADD_USER_GOALS, goals: response.data.goals }
  } else {
    console.error('ERROR ON GETTING USER GOALS')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserDivision(response) {
  if (response.status === 200) {
    return {
      type: constants.ADD_USER_DIVISION,
      user_division: response.data.division_progress,
    }
  } else {
    console.error('ERROR ON GETTING USER DIVISION')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successSettingDevelopmentTrack(response) {
  if (response.status === 201) {
    return {
      type: constants.UPDATE_TRACK_GOAL,
      user_track: response.data.user,
    }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingDivisionData(response) {
  if (response.status === 200) {
    return {
      type: constants.ADD_DIVISION_DATA,
      division_data: response.data.division_stats,
    }
  } else {
    console.error('ERROR ON GETTING DIVISION DATA')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserCourses(response, type) {
  if (response.status === 200) {
    // console.error("ADDING USER COURSES TO STORE: " + response.data.courses)
    if (type === 'completed') {
      return {
        type: constants.ADD_USER_COMPLETED_COURSES,
        courses: response.data.courses,
        pages: response.data.meta.pagination
          ? response.data.meta.pagination.total_pages
          : null,
      }
    } else if (type === 'template') {
      return {
        type: constants.ADD_USER_TEMPLATE_COURSES,
        courses: response.data.courses,
        pages: response.data.meta.pagination
          ? response.data.meta.pagination.total_pages
          : null,
      }
    } else if (type === 'featured') {
      return {
        type: constants.ADD_USER_FEATURED_COURSES,
        courses: response.data.courses,
        pages: response.data.meta.pagination
          ? response.data.meta.pagination.total_pages
          : null,
      }
    } else if (type === 'archived') {
      return {
        type: constants.ADD_USER_ARCHIVED_COURSES,
        courses: response.data.courses,
        pages: response.data.meta.pagination
          ? response.data.meta.pagination.total_pages
          : null,
      }
    } else {
      return {
        type: constants.ADD_USER_COURSES,
        courses: response.data.courses,
        pages: response.data.meta.pagination
          ? response.data.meta.pagination.total_pages
          : null,
        pagination: response.data.meta.pagination,
      }
    }
  } else {
    console.error('ERROR ON GETTING USER COURSES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successProgressingRank(response) {
  if (response.status === 200) {
    // console.error("ADDING ENROLLMENTS TO STATE: " + response.data.course)
    return {
      type: constants.PROGRESS_RANK,
      current_enrollmment: response.data.enrollment,
    }
  } else {
    console.error('ERROR ON GETTING USER COURSES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export const successRequestingUpdateUser = (response) => ({
  type: constants.UPDATE_USER,
  user: response.data.user,
})

export const successRequestingDeleteUser = (id) => ({
  type: constants.DELETE_USER,
  user_id: id,
})

export function successRequestingUpdateCurrentUser(response) {
  if (response.status === 200) {
    // console.error("UPDATING USER and SIGNING IN: " + response.data.user)
    return { type: constants.SIGNIN_USER, user: response.data.user }
  } else {
    console.error('ERROR ON UPDATING USER')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserCurriculum(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_USER_CURRICULUM,
      user_curriculum: response.data.user_curriculum,
    }
  } else {
    console.error('ERROR ON REQUESTING USER CURRICULUM')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

// THUNKS HERE
export function getUser(userId) {
  return function (dispatch) {
    dispatch(showLoading())
    return requestUser(userId)
      .then((response) => dispatch(successRequestingUser(response)))
      .then(() => dispatch(hideLoading()))
  }
}

export function refreshCurrentUser(userId, invitationId = null) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return refreshUser(userId, invitationId)
      .then((response) => { dispatch(successRefreshingCurrentUser(response)); return response })
      .then((response) => { dispatch(common.loadingChanged(false)); return response })
  }
}

export function getUserActivities(userId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUserActivities(userId)
      .then((response) => dispatch(successRequestingUserActivities(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getUserPlayActivities(userId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUserPlayActivities(userId)
      .then((response) =>
        dispatch(successRequestingUserPlayActivities(response))
      )
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getUserGoalActivities(userId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUserGoalActivities(userId)
      .then((response) =>
        dispatch(successRequestingUserGoalActivities(response))
      )
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getUserTrainActivities(userId, page, perPage) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    let meta
    return requestUserTrainActivities(userId, page, perPage)
      .then((response) => {
        dispatch(successRequestingUserTrainActivities(response))
        meta = response.data.meta
      })
      .then(() => (dispatch(common.loadingChanged(false)), meta))
  }
}

export function getUserObjectiveActivities(userId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUserObjectiveActivities(userId)
      .then((response) =>
        dispatch(successRequestingUserObjectiveActivities(response))
      )
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getUserStats(userId) {
  return function (dispatch) {
    return requestUserStats(userId).then((response) =>
      dispatch(successRequestingUserStats(response))
    )
  }
}

export function getUserGoals(userId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUserGoals(userId)
      .then((response) => dispatch(successRequestingUserGoals(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getUserDivision(userId, playType) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUserDivision(userId, playType)
      .then((response) => dispatch(successRequestingUserDivision(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function updateGoalTrack(userId, track) {
  return function (dispatch) {
    return updateDevelopmentTrackGoal(userId, track).then((response) =>
      dispatch(successSettingDevelopmentTrack(response))
    )
  }
}

export function getDivisionData(userId, division_id, play_type, hole_count) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestDivisionData(userId, division_id, play_type, hole_count)
      .then((response) => dispatch(successRequestingDivisionData(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getUserCourses(
  userId,
  type,
  page,
  per_page,
  search
) {
  return function (dispatch) {
    dispatch(showLoading())
    return requestUserCourses(
      userId,
      type,
      page,
      per_page,
      search
    )
      .then((response) =>
        dispatch(successRequestingUserCourses(response, type))
      )
      .then(() => dispatch(hideLoading()))
  }
}

export function getAnnouncementCourses(communityId) {
  return function (dispatch) {
    dispatch(showLoading())
    return requestAnnouncementCourses(communityId)
      .then((response) =>
        dispatch(successRequestingUserCourses(response))
      )
      .then(() => dispatch(hideLoading()))
  }
}

export const failure = (error) => ({ type: constants.UI_ERROR, error })

export function updateUser(user) {
  return function (dispatch) {
    // dispatch(common.loadingChanged(true));
    dispatch({ type: constants.UI_ERROR, error: {} })
    return requestUpdateUser(user)
      .then((response) => dispatch(successRequestingUpdateUser(response)))
      .catch((err) =>
        dispatch({ type: constants.UI_ERROR, error: err.response.data.errors })
      )
  }
}

export function updateCurrentUser(user) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUpdateUser(user)
      .then((response) =>
        dispatch(successRequestingUpdateCurrentUser(response))
      )
      .catch((err) =>
        dispatch({ type: constants.UI_ERROR, error: err.response.data.errors })
      )
  }
}

export function progressRank(user, courseId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestProgressRank(user, courseId)
      .then((response) => dispatch(successProgressingRank(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getUserCurriculum(user, curriculumId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUserCurriculum(user, curriculumId).then((response) =>
      dispatch(successRequestingUserCurriculum(response))
    )
  }
}


export function deleteUser(id, password) {
  return function () {
    return requestDeleteUser(id, password)
  }
}

export function adminDeleteUser(id) {
  return function (dispatch) {
    return requestAdminDeleteUser(id)
      .then((_) => dispatch(successRequestingDeleteUser(id)))
  }
}

export function getActivitiesStats(userIds, eventId) {
  return requestActivitiesStats(userIds, eventId)
}


// API CALLS
function requestUser(userId) {
  const requestUrl = '/api/v1/users/' + userId
  return Axios.get(requestUrl)
}

function refreshUser(userId, invitationId) {
  const requestUrl = `/api/v1/users/${userId}/refresh`
  const params = {}
  if (invitationId) {
    params.invitation_id = invitationId
  }

  return Axios.get(requestUrl, { params })
}

function requestUserActivities(userId) {
  const requestUrl = `/api/v1/users/${userId}/activities`
  return Axios.get(requestUrl)
}

function requestUserPlayActivities(userId) {
  const requestUrl = '/api/v1/users/' + userId + '/play_activities'
  return Axios.get(requestUrl)
}

function requestUserGoalActivities(userId) {
  const requestUrl = '/api/v1/users/' + userId + '/goal_activities'
  return Axios.get(requestUrl)
}

function requestUserTrainActivities(userId, page, perPage) {
  const requestUrl = '/api/v1/users/' + userId + '/practice_activities'
  return Axios.get(requestUrl, {
    params: {
      page: page,
      per_page: perPage,
    },
  })
}

function requestUserObjectiveActivities(userId) {
  const requestUrl = '/api/v1/users/' + userId + '/objective_activities'
  return Axios.get(requestUrl)
}

function requestUserStats(userId) {
  const requestUrl = '/api/v1/users/' + userId + '/stats'
  return Axios.get(requestUrl)
}

function requestUserGoals(userId) {
  const requestUrl = '/api/v1/users/' + userId + '/goals'
  return Axios.get(requestUrl)
}

function requestUserDivision(userId, playType) {
  let requestUrl = '/api/v1/users/' + userId + '/division_progress'
  if (playType && playType !== 'all') {
    requestUrl += '?play_type=' + playType
  }
  return Axios.get(requestUrl)
}

function updateDevelopmentTrackGoal(userId, track) {
  const requestUrl = '/api/v1/users/' + userId + '/update_development_track_goal'
  const data = { development_track_goal: track }
  return Axios.post(requestUrl, data)
}

function requestDivisionData(userId, division_id, play_type, hole_count) {
  let requestUrl = 'api/v1/users/' + userId + '/division_stats'
  let additions = 0
  if (division_id !== 'all') {
    requestUrl += `${additions === 0 ? '?' : '&'}division_id=${division_id}`
    additions++
  }
  if (play_type !== 'all') {
    requestUrl += `${additions === 0 ? '?' : '&'}play_type=${play_type}`
    additions++
  }
  if (hole_count !== 'all') {
    requestUrl += `${additions === 0 ? '?' : '&'}hole_count=${hole_count}`
    additions++
  }
  return Axios.get(requestUrl)
}

function requestUserCourses(
  community_id,
  type,
  page,
  per_page,
  search
) {
  let requestUrl = '/api/v1/communities/' + community_id + '/coach_courses'

  if (community_id && type === 'admin') {
    requestUrl = '/api/v1/users/' + community_id + '/template_courses'
  }

  if (type === 'completed') {
    requestUrl = '/api/v1/communities/' + community_id + '/completed_courses'
  } else if (type === 'archived') {
    requestUrl = '/api/v1/communities/' + community_id + '/archived_courses'
  } else if (type === 'student') {
    requestUrl = '/api/v1/users/' + community_id + '/courses'
  }

  return Axios.get(requestUrl, {
    params: {
      page,
      per_page,
      search,
    },
  })
}

function requestAnnouncementCourses(communityId) {
  const requestUrl = `/api/v1/communities/${communityId}/coach_courses`

  return Axios.get(requestUrl, {
    params: {
      with_completed: true,
    },
  })
}

function requestProgressRank(userId, courseId) {
  let requestUrl = '/api/v1/users/' + userId + '/progress_rank'
  requestUrl += '?user_id=' + userId + '&course_id=' + courseId
  return Axios.get(requestUrl)
}

function requestUpdateUser(user) {
  const requestUrl = '/api/v1/users/' + user.id

  let newUser = user
  newUser.email ||= ''
  newUser.username ||= ''

  // prevent the removing of the email/username when it doest not exist (patch request) but the account type requires it
  // at the same time allow its removal if the account type is changed (and is no longer necessary)
  if (!user.email && user.family_admin) {
    newUser = _.omit(user, ['email'])
  }

  if (!user.username && !user.family_admin) {
    newUser = _.omit(user, ['username'])
  }

  const data = new FormData()

  const allowed_empty = ['email', 'username', 'booking_url', 'headline', 'bio', 'pipedrive_person_id']

  for (const key in newUser) {
    if (newUser[key] !== '' || allowed_empty.includes(key)) {
      if (key === 'uploadPhotoFile') {
        data.append('photo', newUser['uploadPhotoFile'])
      } else {
        data.append(key, newUser[key])
      }
    }
  }

  return Axios.put(requestUrl, data)
}

function requestDeleteUser(id, password) {
  const requestUrl = `/api/v1/users/${id}/delete_account`

  const data = new FormData()
  data.append('current_password', password)

  return Axios.post(requestUrl, data)
}

function requestAdminDeleteUser(id) {
  const requestUrl = `/api/v1/admin/users/${id}`

  return Axios.delete(requestUrl)
}

function requestUserCurriculum(userId) {
  const requestUrl = `/api/v1/users/${userId}/user_curriculum`
  return Axios.get(requestUrl)
}

function requestActivitiesStats(ids, eventId) {
  const requestUrl = `/api/v1/users/activities_stats?user_ids=${JSON.stringify(ids)}&event_id=${eventId}`
  return Axios.get(requestUrl)
}
